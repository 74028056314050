import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Stack from 'react-bootstrap/Stack'

import Related from '../components/related'
import PriceDisplay from './price-display'
import PercentageOff from './percentage-off'
import ESW_CLASSNAMES from '../esw/classnames'
import useGetPrice from '../hooks/useGetPrice'

import iscollectionicon from '../images/svgs/is-collection-icon-black.svg'

export default function ProductCardMini({ product, onClick, children }) {
    const {
        product_name,
        description,
        slug,
        shop_categories,
        includes,
        requires,
        miniCardFile,
        prices,
        badge,
    } = product

    const url = `/${shop_categories[0].slug}/${slug}`
    const is_collection = includes?.length > 0
    const is_expansion = requires?.length > 0
    const category = shop_categories[0]
    const { price } = useGetPrice(prices)

    if (!product) return <></>
    else return (
        <Col
            sm={6}
            lg={4}
            key={`product-card-mini-${slug}`}
            className="mb-4 position-relative"
        >
            {children && children}
            <Link to={url} className="text-black" onClick={onClick}>
                <Stack direction="horizontal" className="h-100 align-items-start position-relative" style={{ minHeight: 200 }}>
                    {/* IMAGE AND BADGE */}
                    <div className='h-100 bg-white overflow-hidden flex-shrink-0' style={{ borderRadius: '12px 0px 0px 12px' }}>
                        <GatsbyImage image={getImage(miniCardFile[0])} alt='' />
                        {(badge || price?.promotion) && <p
                            className="fw-bold mb-0 bg-primary shadow position-absolute"
                            style={{ left: -4, marginRight: -4, padding: '0px 12px', fontSize: 14 }}
                        >
                            {badge ? badge : <><PercentageOff price={price} />% OFF</>}
                        </p>}
                    </div>

                    {/* TITLE, CATEGORY, DESCRIPTION, INCLUDES/REQUIRES, PRICE */}
                    <Card className="h-100 w-100 border-0" style={{ padding: '20px 20px 16px', borderRadius: '0px 12px 12px 0px' }}>
                        <Card.Body className="p-0">
                            <Card.Title className="text-uppercase mb-3" style={{ fontSize: 24, lineHeight: '28px' }}>
                                {product_name}
                            </Card.Title>

                            <Card.Subtitle as='p' className="fw-semibold text-uppercase text-secondary mb-3">
                                {category.name}
                            </Card.Subtitle>

                            <Card.Text
                                className={`truncate-lines-2 ${(includes?.length || requires?.length) ? 'mb-0 text-truncate' : ''}`}
                                title={description?.replace(/(<([^>]+)>)/gi, ' ')}
                            >
                                {description?.replace(/(<([^>]+)>)/gi, ' ').replace('&nbsp;', '')}
                            </Card.Text>

                            {is_collection && <Related products={includes} truncate className="small">Includes: </Related>}
                            {is_expansion && <Related products={requires} className="small">Requires: </Related>}
                        </Card.Body>
                        <Card.Footer className="bg-transparent border-0 p-0">
                            <div className="d-flex justify-content-between">
                                <PriceDisplay
                                    prices={prices}
                                    style={{ fontSize: 18 }}
                                    className='fw-semibold m-0 d-flex align-items-center gap-2'
                                    eswPriceClassName={ESW_CLASSNAMES.PAGE_PRICE}
                                    eswPriceStrikedClassname={ESW_CLASSNAMES.DISCOUNT_ITEM}
                                />
                                {is_collection && <img src={iscollectionicon} alt='' title='Type: Collection' />}
                            </div>
                        </Card.Footer>
                    </Card>
                </Stack>
            </Link>
        </Col>
    )
}

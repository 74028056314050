import React, { useContext, useEffect, useRef } from 'react'
import { navigate } from 'gatsby'
import Button from 'react-bootstrap/Button'
import { useSearchBox, useInfiniteHits } from 'react-instantsearch'
import AppContext from '../context/app'
import searchIcon from '../images/svgs/search-icon-white.svg'

export default function Search() {
    const { setSearchClicked } = useContext(AppContext)
    const { query, refine, clear } = useSearchBox()
    const { results } = useInfiniteHits()
    const inputRef = useRef(null)
    
    useEffect(() => {
        if (typeof document === 'undefined') return
        document.addEventListener('keydown', e => handleKeyDown(e))
        return document.removeEventListener('keydown', e => handleKeyDown(e))
    }, [])

    function handleKeyDown(e){
        // only do stuff if the search input is focused
        // if (document.activeElement !== inputRef.current) return
        if (e.key === 'Escape') {
            clear()
            setSearchClicked(false)
        }
    }
    
    function handleSubmit(e) {
        e.preventDefault()
        navigate('/search', { state: results })
        clear()
    }

    return (
        <form
            onSubmit={handleSubmit}
            style={{ border: '1px solid white', borderRadius: 16, width: 206, padding: '3px 0px' }}
            className='flex-center overflow-hidden'
        >
            <Button
                variant=''
                onClick={() => {
                    inputRef.current.focus()
                    setSearchClicked(true)
                }}
                className='py-0 border-0 d-flex'
                style={{ paddingLeft: 8, paddingRight: 4 }}
            >
                <img src={searchIcon} alt='' />
            </Button>

            <input
                ref={inputRef}
                type='text'
                id='nav-search-input'
                style={{ background: 'black', border: 'none', color: 'white', outline: 'none' }}
                className='fw-bold-placeholder placeholder-font-family-primary hide-placeholder-on-focus w-100 p-0'
                placeholder='SEARCH THE AIR STORE'
                onChange={e => refine(e.target.value)}
                onClick={() => setSearchClicked(true)}
                value={query}
                autoComplete='off'
            />
        </form>
    )
}
import { useStaticQuery, graphql } from 'gatsby'

export default function useGetSiteMetadata() {
    const data = useStaticQuery(graphql`
        query siteMetadataQuery {
            site{
                siteMetadata{
                    title
                    siteUrl
                    brandName
                    socials{
                        name
                        url
                        iconRelativePath
                    }
                }
            }
        }
    `)
    return data.site.siteMetadata
}

const BRAND_ID = 5896

const PIMCORE_URL = 'https://headless.inmusicbrands.com' // PROD
// const PIMCORE_URL = 'https://staging.headless.inmusicbrands.com' // STAGING

const SUITE_CRM_URL = 'https://stg.crmtool.inmusicbrands.com'

const RECAPTCHA_KEY = '6Lfq808qAAAAAKyGd7wCWCwZjmZwXP_lN-9KclAV'

const ENDPOINTS = {
    GET_CART_ID: (iso) => `/api/${iso.toLowerCase()}/get_cart_id`,
    ADD_TO_CART: (iso) => `/api/${iso.toLowerCase()}/add_to_cart`,
    REMOVE_FROM_CART: `/api/remove_from_cart`,
    GET_CART: (iso) => `/api/${iso.toLowerCase()}/get_cart`,
    CATEGORY: `/api/get_categories?brand_id=${BRAND_ID}`,
    PRODUCT: `/api/get_products?product_type=software`,
    PRICE: `/api/get_prices?product_type=software&brand_id=${BRAND_ID}`,
    PROMOTION: `/api/get_promotions`,
    COUNTRY: `/api/pim/countries`,
    HOMEPAGE: `/api/homepage/${BRAND_ID}`,
    REDEEM_VOUCHER: (iso) => `/api/${iso.toLowerCase()}/redeem-voucher`,
    ROLLBACK_VOUCHER: (iso) => `/api/${iso.toLowerCase()}/rollback-redemption`,
    REGISTER_PRODUCT: (iso) => `/api/${iso.toLowerCase()}/register-product`,
    SIGNUP: `/legacy/Api/index.php/custom/Signups`,
}

const NODE_TYPES = {
    CATEGORY: 'Category',
    PRODUCT: 'Product',
    PRICE: 'Price',
    PROMOTION: 'Promotion',
    COUNTRY: 'Country',
    HOMEPAGE: 'Homepage'
}

const BASE_COUNTRY_ISO = 'DE'

module.exports = {
    BRAND_ID,
    PIMCORE_URL,
    SUITE_CRM_URL,
    RECAPTCHA_KEY,
    ENDPOINTS,
    NODE_TYPES,
    BASE_COUNTRY_ISO,
}

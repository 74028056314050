import React, { useState } from 'react'
import { BRAND_ID, RECAPTCHA_KEY, ENDPOINTS, SUITE_CRM_URL } from '../../gatsby-node/constants'
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import ls from 'local-storage'
import bg from '../images/blackfriday/3fb920d552740b3bc9b2fa3b6d58c100.png'

export default function EmailSignup() {
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    
    const handleSubmit = (e) => {
        setLoading(true)
        e.preventDefault()
        e.stopPropagation()
        if (typeof window === `undefined`) return

        window.grecaptcha.enterprise.ready(async () => {
            const token = await window.grecaptcha.enterprise.execute(RECAPTCHA_KEY, { action: 'submit' })

            const form = e.target
            const name = form.name.value
            const email = form.email.value

            const data = {
                firstName: name,
                lastName: '',
                email,
                title: "",
                websiteID: BRAND_ID,
                customerGroup: "General",
                gender: "",
                dob: "",
                SubscribedBrands: "Air Music Tech",
                company: null,
                telephone: "",
                country: "",
                dataSource: "Air Music Tech Website",
                specialCategory: 1,
                specialCategoryName: "Air Music Tech 10% OFF",
                user_response_token: token,
            }

            const url = `${SUITE_CRM_URL}${ENDPOINTS.SIGNUP}`
            const options = {
                headers: {
                    "Content-Type": "application/json",
                },
                method: "post",
                body: JSON.stringify(data),
            }

            try {
                const r = await fetch(url, options)
                setSuccess(r.ok)
                if (r.ok) {
                    ls.set('allowPopup', false)
                }
            } catch (error) {
                console.error(error)
            } finally {
                setLoading(false)
            }
        })
    }

    const inputStyle = {
        borderRadius: 0,
        height: 44,
        background: 'transparent',
        color: 'white',
        fontWeight: 500,
    }

    // don't show on the /blackfriday or /mastering-offer pages
    if (typeof window !== `undefined` && (window.location.pathname === '/blackfriday/' || window.location.pathname === '/mastering-offer/')) return
    else return (
        <Container fluid style={{
            backgroundImage: `url(${bg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            position: 'relative',
        }}
            className='p-0 p-md-5'
        >
            <Container>
                <Row style={{ minHeight: 400 }} className=''>
                    <Col className='text-white my-auto py-5'>
                        <h6 style={{ fontSize: 60, lineHeight: '56px' }} className='text-uppercase changeling-neo'>
                            <span className='stroke'>
                                STAY INSPIRED
                            </span>
                            <br/>
                            <span className=''>
                                STAY AHEAD
                            </span>
                        </h6>
                        <p>
                            Join our community, sharing handy production and artistic hacks.<br/>
                            <strong>Sign up now and get 10% off your next order!</strong>
                        </p>
                    </Col>
                    <Col className='mt-auto mb-5'>
                        {success && <div className='d-flex justify-content-center align-items-center' style={{ height: 198 }}>
                            <p className='text-white ff-primary text-uppercase fw-bold' style={{ fontSize: 32 }}>
                                Thanks for signing up!
                            </p>
                        </div>}
                        {!success && <Form id='email-signup' onSubmit={handleSubmit}>
                            <Form.Group className="mb-4" controlId="name">
                                <Form.Control placeholder="YOUR NAME" style={inputStyle} className='ff-primary' required />
                            </Form.Group>
                            <Form.Group className='mb-2' controlId="email">
                                <Form.Control type="email" placeholder="YOUR EMAIL" style={inputStyle} className='ff-primary' required />
                            </Form.Group>
                            <Form.Text style={{ color: 'rgba(255, 255, 255, .7)', fontSize: 12 }}>
                                This site is protected by reCAPTCHA and the Google&nbsp;
                                <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                                <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                            </Form.Text>
                            <Button disabled={loading} type="submit" className='d-block text-uppercase' style={{ marginTop: 40, marginLeft: 'auto', width: 208, height: 46 }}>
                                {loading ?
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                    :
                                    <>Subscribe for 10% Off ️</>
                                }
                            </Button>
                        </Form>}
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}
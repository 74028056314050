import React, { useContext } from 'react'
import AppContext from '../context/app'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import whitebag from '../images/svgs/bag-icon-white.svg'
import yellowbag from '../images/svgs/bag-icon-yellow.svg'

export default function Bag() {
    const {
        showCart,
        setShowCart,
        cart,
        loadingCart
    } = useContext(AppContext)

    const bubbleStyle = {
        top: 2,
        right: -12,
        fontSize: 10,
        width: 16,
        height: 16,
        borderRadius: 16,
    }
    
    return (
        <Button variant='' onClick={() => setShowCart(!showCart)} className='p-0 position-relative'>
            {
                cart?.total_count ?
                    <>
                        <img src={yellowbag} alt='' />
                        <span style={bubbleStyle} className='bg-primary position-absolute inline-flex-center fw-bold'>
                            {
                                loadingCart ?
                                    <Spinner variant='dark' animation="grow" role="status" size='sm'>
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                    :
                                    cart?.total_count > 9 ? '9+' : cart?.total_count
                            }
                        </span>
                    </>
                    :
                    <img src={whitebag} alt='' />
            }
        </Button>
    )
}
import { PIMCORE_URL, ENDPOINTS } from "../../gatsby-node/constants"
import Cookies from 'js-cookie'

export default async function getCartId(value) {
    const { country, setCartId } = value

    try {
        const r = await fetch(`${PIMCORE_URL}${ENDPOINTS.GET_CART_ID(country.node.country_iso_code)}`)
        const { success, sale, session_cart_id } = await r.json()
    
        if (success && sale) {
            Cookies.set('eswCartId', `cart_${session_cart_id}`)
            setCartId(session_cart_id)
        } else {
            console.error(`Failed to get a cart id`)
        }
    } catch (err) {
        console.error(err)
    }
}
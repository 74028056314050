import React, { useState, useEffect } from 'react'
import { BRAND_ID, RECAPTCHA_KEY, ENDPOINTS, SUITE_CRM_URL } from '../../gatsby-node/constants'
import { Col, Container, Row, Modal, Form, Button, Spinner } from 'react-bootstrap'
import bg from '../images/blackfriday/3fb920d552740b3bc9b2fa3b6d58c100.png'
import ls from 'local-storage'
import x from '../images/svgs/x-icon-gray.svg'

export default function SignupModal() {
    const [allowPopup, setAllowPopup] = useState(ls.get('allowPopup'))

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => {
        ls.set('allowPopup', false)
        setAllowPopup(false)
        setShow(false)
    }

    useEffect(() => {
        if (typeof window === `undefined`) return
        if (window.location.pathname === '/blackfriday/') return
        if (window.location.pathname === '/mastering-offer/') return
        handleShow()
    }, [])

    const handleSubmit = (e) => {
        setLoading(true)
        e.preventDefault()
        e.stopPropagation()
        if (typeof window === `undefined`) return

        window.grecaptcha.enterprise.ready(async () => {
            const token = await window.grecaptcha.enterprise.execute(RECAPTCHA_KEY, { action: 'submit' })

            const form = e.target
            const name = form.name.value
            const email = form.email.value

            const data = {
                firstName: name,
                lastName: '',
                email,
                title: "",
                websiteID: BRAND_ID,
                customerGroup: "General",
                gender: "",
                dob: "",
                SubscribedBrands: "Air Music Tech",
                company: null,
                telephone: "",
                country: "",
                dataSource: "Air Music Tech Website",
                specialCategory: 1,
                specialCategoryName: "Air Music Tech 10% OFF",
                user_response_token: token,
            }

            const url = `${SUITE_CRM_URL}${ENDPOINTS.SIGNUP}`
            const options = {
                headers: {
                    "Content-Type": "application/json",
                },
                method: "post",
                body: JSON.stringify(data),
            }

            try {
                const r = await fetch(url, options)
                setSuccess(r.ok)
                if (r.ok) {
                    ls.set('allowPopup', false)
                }
            } catch (error) {
                console.error(error)
            } finally {
                setLoading(false)
            }
        })
    }

    const inputStyle = {
        borderRadius: 0,
        height: 44,
        background: 'transparent',
        color: 'white',
        fontWeight: 500,
    }
    
    if(allowPopup === false) return
    else return (
        <Modal show={show} onHide={handleClose} centered size='xl'>
            <Modal.Body style={{
                backgroundImage: `url(${bg})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                position: 'relative',
            }}
                className='px-5 py-lg-0'
            >
                <Container className='px-xl-5 p-0'>
                    <Row style={{minHeight: 400}} className='align-items-center'>
                        <Col className='text-white mb-3' lg>
                            <h6 style={{fontSize: 32}} className='text-uppercase'>
                                Hey, thanks for<br />
                                stopping by.<br />
                                <span className='text-primary'>
                                    Here's 10% off.
                                </span>
                            </h6>
                            <p>
                                <strong>Sign up for our email list and grab 10% off instantly.</strong><br/>
                                We've got the scoop for the latest gear, tips, and<br />
                                tricks for serious music production.
                            </p>
                       </Col>
                        <Col>
                            {success && <div className='d-flex flex-column justify-content-center align-items-center' style={{height: 198}}>
                                <p className='text-white ff-primary text-uppercase fw-bold' style={{ fontSize: 32 }}>
                                    Thanks for signing up!
                                </p>
                                <Button onClick={handleClose}>
                                    CLOSE POPUP
                                </Button>
                            </div>}
                            {!success && <Form id='email-signup' onSubmit={handleSubmit}>
                                <Form.Group className="mb-4" controlId="name">
                                    <Form.Control placeholder="YOUR NAME" style={inputStyle} className='ff-primary' required />
                                </Form.Group>
                                <Form.Group className='mb-2' controlId="email">
                                    <Form.Control type="email" placeholder="YOUR EMAIL" style={inputStyle} className='ff-primary' required />
                                </Form.Group>
                                <Form.Text style={{ color: 'rgba(255, 255, 255, .7)', fontSize: 12 }}>
                                    This site is protected by reCAPTCHA and the Google&nbsp;
                                    <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                                    <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                                </Form.Text>
                                <Button disabled={loading} type="submit" className='d-block' style={{ marginTop: 40, marginLeft: 'auto', width: 139, height: 46 }}>
                                    {loading ?
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                        :
                                        <>JOIN NOW</>
                                    }
                                </Button>
                            </Form>}
                        </Col>
                    </Row>
                </Container>

                <button
                    onClick={handleClose}
                    style={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        background: 'transparent',
                        padding: 8,
                        border: 'none',
                    }}
                >
                    <img
                        onMouseEnter={e => e.target.classList.toggle('scale')}
                        onMouseLeave={e => e.target.classList.toggle('scale')}
                        src={x}
                        alt='close'
                        height={24}
                        width={24}
                    />
                </button>
            </Modal.Body>
        </Modal>
    )
}

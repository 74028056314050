import { useEffect } from 'react'
import useGetAllCountry from './useGetAllCountry'
import { BASE_COUNTRY_ISO } from '../../gatsby-node/constants'
import Cookies from 'js-cookie'
import ls from 'local-storage'
import getCart from '../utilities/get-cart'
import rollbackVoucher from '../utilities/rollback-voucher'
import applyVoucher from '../utilities/apply-voucher'

// https://medium.com/geographit/accessing-react-state-in-event-listeners-with-usestate-and-useref-hooks-8cceee73c559
export default function useWindowEventListenersEffect(value) {
    const {
        setCountry,
        showCart, setShowCart,
        showNav, setShowNav,
        setScreenWidth,
        setClientWidth,
        searchClicked, setSearchClicked,
        setSingularDeviceId,
    } = value
    const allCountry = useGetAllCountry()

    // EVENT LISTENERS THAT DO NOT DEPEND ON APP STATE
    useEffect(() => {
        if (typeof window === 'undefined') return
        if (typeof document === 'undefined') return

        const getCountryByIso = (iso) => {
            const isCountry = ({ node: { country_iso_code } }) => country_iso_code === iso
            const isBaseCountry = ({ node: { country_iso_code } }) => country_iso_code === BASE_COUNTRY_ISO

            const isCountryInList = allCountry.some(isCountry)
            return allCountry.find(isCountryInList ? isCountry : isBaseCountry)
        }

        const updateCountry = (country) => {
            const { node: { country_iso_code, currency_iso_code } } = country
            
            const cookieIso = Cookies.get('eswCountryIso')
            if (cookieIso && (cookieIso === country_iso_code)) {
                setCountry(country)
            } else {
                Cookies.set('eswCountryIso', country_iso_code)
                Cookies.set('eswCurrencyIso', currency_iso_code?.toUpperCase())
                ls.set('SELECTED_COUNTRY_ISO', country_iso_code)
            }
        }

        const handleGeoip = ({ detail }) => {
            const iso = JSON.parse(detail)?.country?.iso_code
            const country = getCountryByIso(iso)
            updateCountry(country)
        }

        const handleCountryCurrencyChanged = ({ detail }) => {
            const iso = detail?.countryIso
            const country = getCountryByIso(iso)
            updateCountry(country)
        }

        const handleEswCountryPicingSet = ({ detail }) => {
            const iso = detail?.countryIso
            const country = getCountryByIso(iso)
            setCountry(country)
        }

        const handleResize = (e) => {
            setScreenWidth(e.target.screen.width)
            setClientWidth(e.target.document.body.clientWidth)
        }

        const handleSingularInitFinished = ({ detail }) => {
            setSingularDeviceId(detail)
        }

        window.addEventListener('MAXMIND_GEO_IP', handleGeoip)
        window.addEventListener('COUNTRY_CURRENCY_CHANGED', handleCountryCurrencyChanged)
        window.addEventListener('resize', handleResize)
        window.addEventListener('SINGULAR_INIT_FINISHED', handleSingularInitFinished)
        document.addEventListener('ESW_COUNTRY_PRICING_SET', handleEswCountryPicingSet)
        
        return () => {
            window.removeEventListener('MAXMIND_GEO_IP', handleGeoip)
            window.removeEventListener('COUNTRY_CURRENCY_CHANGED', handleCountryCurrencyChanged)
            window.removeEventListener('resize', handleResize)
            window.removeEventListener('SINGULAR_INIT_FINISHED', handleSingularInitFinished)
            document.removeEventListener('ESW_COUNTRY_PRICING_SET', handleEswCountryPicingSet)
        }
    }, [])

    // EVENT LISTENERS THAT DO DEPEND ON APP STATE
    useEffect(() => {
        if (typeof window === 'undefined') return

        const handleCartChange = async (e) => {
            await getCart(e, value)
        }

        const handleNavigate = () => {
            if (showCart) setShowCart(false)
            if (showNav) setShowNav(false)
            if (searchClicked) setSearchClicked(false)
        }

        window.addEventListener('CART_CHANGE', handleCartChange)
        window.addEventListener('NAVIGATE', handleNavigate)

        return () => {
            window.removeEventListener('CART_CHANGE', handleCartChange)
            window.removeEventListener('NAVIGATE', handleNavigate)
        }
    }, [value])
}
